import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-duration-format";
import * as styles from "./countDown.module.css";

export const CountDown = ({ base }) => {
  const [countDown, setCountDown] = useState(null);
  const date = new moment(base);
  const diff = date.diff(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      const countDown = {
        days: moment.utc(diff).format("DD") - 1,
        hours: moment.utc(diff).format("HH"),
        minutes: moment.utc(diff).format("mm"),
        seconds: moment.utc(diff).format("ss"),
      };

      setCountDown(countDown);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  return (
    countDown && (
      <div className={styles.content}>
        <div>
          <div>{countDown.days}</div>
          <div>jours</div>
        </div>
        <div>
          <div>{countDown.hours}</div>
          <div>heures</div>
        </div>
        <div>
          <div>{countDown.minutes}</div>
          <div>minutes</div>
        </div>
        <div>
          <div>{countDown.seconds}</div>
          <div>secondes</div>
        </div>
      </div>
    )
  );
};
