import * as React from "react";
import Homepage from "../scenes/Homepage/Homepage";
import { MainPageContext } from "../helpers/pagesContext";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { CLASSEMENT } from "../helpers/route";

export default function Home(props) {
  return (
    <Layout currentRoute={CLASSEMENT}>
      <MainPageContext.Provider value={props.data}>
        <Homepage />
      </MainPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    contentfulMainPage {
      title
      ouverture
      ouvertureMessage
      active
      headTtitreUn
      headTitreDeux
      description
      boardHead
      aucunParticipants
      marathonClosed
      personnes {
        nom
        prnom
        progression
        cv
        event
        title
        linkedin
        contact
      }
    }
  }
`;
