import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { MainPageContext } from "../../helpers/pagesContext";
import * as styles from "./Homepage.module.css";
import Header from "../../components/Header/Header";
import { StarsTitle } from "../../components/StarsTitle/StarsTitle";
import moment from "moment";
import { CountDown } from "../../components/CountDown/CountDown";

export default function Homepage() {
  const {
    contentfulMainPage: {
      headTtitreUn,
      headTitreDeux,
      description,
      boardHead,
      personnes,
      aucunParticipants,
      marathonClosed,
      active,
      ouverture,
      ouvertureMessage,
    },
  } = useContext(MainPageContext);

  const [list, setList] = useState([]);
  const date = moment(ouverture);

  const totalPoint = (personne) =>
    personne.cv + personne.event + personne.linkedin + personne.contact;

  useEffect(() => {
    const array = personnes.sort((a, b) => {
      const first = totalPoint(a);
      const second = totalPoint(b);
      return second - first;
    });
    setList(array);
  }, []);

  return (
    <div>
      <Header firstLine={headTtitreUn} SecondLine={headTitreDeux} />
      <section className={styles.container}>
        {active ? (
          list.length === 1 && list[0].title === "dummy" ? (
            date.isBefore() ? (
              <div className={styles.filling}>{aucunParticipants}</div>
            ) : (
              <>
                <StarsTitle title={ouvertureMessage} />
                <div className={styles.filling}>
                  <CountDown base={ouverture} />
                </div>
              </>
            )
          ) : (
            list.length !== 0 && (
              <div className={styles.paper}>
                <StarsTitle title={description} />
                <div className={styles.listCcontainer}>
                  <div
                    className={clsx(styles.personneContainer, styles.listHead)}
                  >
                    {boardHead.map((head, i) => (
                      <div key={i}>{head}</div>
                    ))}
                  </div>
                  {list.map(
                    (
                      { cv, event, linkedin, contact, nom, prnom, progression },
                      i
                    ) => {
                      return (
                        <div
                          className={clsx(
                            styles.personneContainer,
                            styles.listBody
                          )}
                          key={i}
                        >
                          <div className={styles.rank}>{i + 1}</div>
                          <div
                            className={clsx(
                              styles.progression,
                              progression > 0
                                ? styles.up
                                : progression === 0
                                ? styles.stay
                                : styles.down
                            )}
                          >
                            {progression > 0 ? "+" + progression : progression}
                          </div>
                          <div className={styles.name}>{`${nom} ${prnom}`}</div>
                          <div className={styles.infos}>{`${cv}`}</div>
                          <div className={styles.infos}>{`${event}`}</div>
                          <div className={styles.infos}>{`${linkedin}`}</div>
                          <div className={styles.infos}>{`${contact}`}</div>
                          <div>
                            <div
                              className={clsx(
                                styles.total,
                                i === 0 && styles.winner
                              )}
                            >{`${+event + +cv + +linkedin + +contact}`}</div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )
          )
        ) : (
          <div className={styles.filling}>{marathonClosed}</div>
        )}
      </section>
    </div>
  );
}
